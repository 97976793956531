import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Import social icons
import Github from "react-ionicons/lib/LogoGithub"
import Linkedin from "react-ionicons/lib/LogoLinkedin"
import Twitter from "react-ionicons/lib/LogoTwitter"
import Email from "react-ionicons/lib/MdMail"
import { useStaticQuery, graphql } from "gatsby"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      site {
        siteMetadata {
          socials {
            twitter
            github
            linkedin
            email
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Contact" description="I’d love to hear from you!" />
      <div>
        <h1>I’d love to hear from you!</h1>
        <h4 className="text-gray-800 dark:text-brand-400 text-2xl font-sans mt-8 font-bold mb-4">
          Contact
        </h4>
        <p>
          I'm always open to chat about pretty much everything. You can shoot me
          an email to{" "}
          <a
            href="mailto:jgallardo9814@gmail.com"
            className="font-medium text-gray-900 dark:text-brand-400 underline"
          >
            jgallardo9814@gmail.com
          </a>{" "}
          for any business enquiry or question you may have. In case your{" "}
          <span role="img" aria-label="heart">
            ❤️
          </span>{" "}
          belongs to social networks, you can dm me through any of my accounts:
        </p>
        <ul className="flex w-full m-0 p-0 mb-6">
          <li>
            <a
              href={`https://github.com/${data.site.siteMetadata.socials.github}`}
              target="_blank"
              rel="noreferrer"
              rel="noreferrer"
            >
              <Github className="fill-current"></Github>
            </a>
          </li>
          <li className="ml-2">
            <a
              href={`https://linkedin.com/in/${data.site.siteMetadata.socials.linkedin}`}
              target="_blank"
              rel="noreferrer"
              rel="noreferrer"
            >
              <Linkedin className="fill-current"></Linkedin>
            </a>
          </li>
          <li className="ml-2">
            <a
              href={`https://twitter.com/${data.site.siteMetadata.socials.twitter}`}
              target="_blank"
              rel="noreferrer"
              rel="noreferrer"
            >
              <Twitter className="fill-current"></Twitter>
            </a>
          </li>
          <li className="ml-2">
            <a
              href={`mailto:${data.site.siteMetadata.socials.email}`}
              target="_blank"
              rel="noreferrer"
              rel="noreferrer"
            >
              <Email className="fill-current"></Email>
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default Contact
